import React from 'react';
import 'assets/scss/utils/_fonts.css';
import 'assets/scss/style.scss';
import Header from 'components/Header';
import Hero from 'components/Hero';
import About from 'components/About';
import Services from 'components/Services';
import Work from 'components/Work';
import Technology from 'components/Technology';
import Contact from 'components/Contact';
import Footer from 'components/Footer';
import {ScrollLocky, getGapWidth} from 'react-scroll-locky';
import {passiveIfPossible} from 'lib/events';
import {documentTopOffset} from 'lib/helpers';
import {animateScroll} from 'react-scroll';
import {throttle} from 'throttle-debounce';
import SmoothScroll from 'smoothscroll-for-websites';
import 'lib/webp';

import 'assets/img/favicon-16x16.png';
import 'assets/img/favicon-32x32.png';
import 'assets/img/favicon-64x64.png';
import 'assets/img/apple-touch-icon.png';
import 'assets/img/safari-pinned-tab.svg';
import 'assets/img/site.webmanifest';
import 'assets/img/og.jpg';
import 'assets/img/twitter.jpg';
import '../sitemap.xml';

const MOBILE_BREAKPOINT = 767;
const DESKTOP_HEADER_MIN_HEIGHT = 80;
const DESKTOP_HEADER_MAX_HEIGHT = 156;
const MOBILE_HEADER_MIN_HEIGHT = 60;
const MOBILE_HEADER_MAX_HEIGHT = 96;
const DESKTOP_HEADER_CONTENT_OFFSET = 22;
const MOBILE_HEADER_CONTENT_OFFSET = 12;

class App extends React.PureComponent {

    constructor(props) {  
        super(props);

        this.state = {
            fontLoaded: true,
            gapWidth: null,
            menuOpen: false,
            menuOpenLogoAnimation: false,
            mneuActiveItem: 0,
            device: null,
            deviceWidth: 0,
            workDetail: null,
            workCloseDetail: false,
            scrollBlockEnabled: false,
            headerFixed: false,
            contactSubject: '',
            scrollActive: false,
        }

        this.sectionsRefs = {
            hero: React.createRef(),
            heroContent: React.createRef(),
            heroMouse: React.createRef(),
            about: React.createRef(),
            services: React.createRef(),
            work: React.createRef(),
            technology: React.createRef(),
            contact: React.createRef(),
            header: React.createRef(),
            headerWrapper: React.createRef(),
            content: React.createRef(),
        }

        this.sections = {
            hero: 0,
            services: 0,
            technology: 0,
            work: 0,
            contact: 0,
        }

        this.heroHeight
        this.headerHeight
        this.heroContentPadding
        this.scrollTop
    }
    
    componentDidMount() {
        SmoothScroll();
        this.handleResize();
        window.addEventListener('resize', this.handleResize, passiveIfPossible);
        window.addEventListener('scroll', this.handleScroll, passiveIfPossible);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleResize = () => {
        const headerFixed = this.state.headerFixed;
        const hero = this.sectionsRefs.hero.current;
        const header = this.sectionsRefs.header.current;

        let HEADER_MIN_HEIGHT = DESKTOP_HEADER_MIN_HEIGHT;
        let device;

        if (window.innerWidth > MOBILE_BREAKPOINT) {
            device = 'desktop';
        } else {
            device = 'mobile';
            HEADER_MIN_HEIGHT = MOBILE_HEADER_MIN_HEIGHT;
        }

        if (!headerFixed) {
            this.headerHeight = header.clientHeight;
        }
        this.heroHeight = hero.clientHeight;

        throttle(100, this.scrollAnimations());

        this.setState({
            gapWidth: getGapWidth('margin'),
            device,
            deviceWidth: window.innerWidth
        });
    }

    handleScroll = () => throttle(100, this.scrollAnimations());

    scrollAnimations = () => {
        this.getAnchorPoints();

        if (this.parallaxHero) {
            window.cancelAnimationFrame(this.parallaxHero);
        }

        this.parallaxHero = window.requestAnimationFrame(this.parallaxHeroAnimation)
    }

    smoothAnchorMenu = () => {
        const headerHeight = this.sectionsRefs.header.current.clientHeight;
        const curPos = window.scrollY + headerHeight;
        const hasFullHeight = this.state.device === 'desktop' && !this.state.headerFixed;
        let curSection = null;

        if (curPos > this.sections.contact) {
            curSection = 'contact';
        } else if(curPos > this.sections.work) {
            curSection = 'work';
        } else if(curPos > this.sections.technology) {
            curSection = 'technology';
        } else if(curPos > this.sections.services + (hasFullHeight ? 80 : 0)) {
            curSection = 'services';
        } else if(curPos > this.sections.hero || curPos === 0) {
            curSection = 'hero';
        } else {
            curSection = 1;
        }
        
        if(this.state.mneuActiveItem !== curSection && !this.state.scrollActive) {            
            this.setState({mneuActiveItem: curSection});
        }
    }

    getAnchorPoints() {
        const curScroll = window.scrollY;
    
        for(var key in this.sections) {     
          this.sections[key] = this.sectionsRefs[key].current.getBoundingClientRect().top + curScroll;
        }
        
        /* Get the pixel height of the viewport */
        const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        
        /* Get the pixel-depth of the bottom of the page */
        const bottom = document.body.offsetHeight;
        
        /* If max-scroll is less than the pixel-depth of Section 3, then adjust the pixel-depth of Section 3 to be 50px higher than max scroll depth ... allowing it to be an active destination */
        if(viewPortHeight > (bottom - this.sections.Section3)) {
          this.sections['contact'] = bottom - viewPortHeight - 50;
        }
    
        this.smoothAnchorMenu();
    }

    parallaxHeroAnimation = () => {
        const scrollTop = documentTopOffset(),
            contentTop = this.sectionsRefs.services.current.getBoundingClientRect().top,
            header = this.sectionsRefs.header.current,
            headerWrapper = this.sectionsRefs.headerWrapper.current,
            heroContent = this.sectionsRefs.heroContent.current,
            headerInnerHeight = headerWrapper.clientHeight,
            device = this.state.device,
            headerFixed = this.state.headerFixed,
            isMobile = device === 'mobile';

        let HEADER_MIN_HEIGHT = DESKTOP_HEADER_MIN_HEIGHT,
            HEADER_MAX_HEIGHT = DESKTOP_HEADER_MAX_HEIGHT,
            HEADER_CONTENT_OFFSET = DESKTOP_HEADER_CONTENT_OFFSET;

        if (isMobile) {
            HEADER_MIN_HEIGHT = MOBILE_HEADER_MIN_HEIGHT;
            HEADER_MAX_HEIGHT = MOBILE_HEADER_MAX_HEIGHT
            HEADER_CONTENT_OFFSET = MOBILE_HEADER_CONTENT_OFFSET;
        }

        if (scrollTop > 0) {
            this.heroContentPadding = scrollTop;
            this.sectionsRefs.heroMouse.current.classList.add('is-hidden');

            // Desktop
            if (!isMobile) {
                if (scrollTop >= this.heroHeight - this.headerHeight) {
                    let newOffset = (contentTop - headerInnerHeight) / 2;
                    let newOpacity = Math.abs((contentTop - this.headerHeight) / HEADER_MIN_HEIGHT).toFixed(2);

                    if (parseInt(newOpacity, 10) < 0) {
                        newOpacity = 0;
                    }

                    if (newOffset < HEADER_CONTENT_OFFSET) {
                        newOffset = HEADER_CONTENT_OFFSET;
                        this.setState({headerFixed: true});
                    } 
                    else {
                        this.setState({headerFixed: false});
                    }
            
                    headerWrapper.style.transform = 'translateY(' + newOffset + 'px)';

                    if (newOpacity < 1) {
                        header.style.backgroundColor = 'rgba(255, 255, 255, ' + newOpacity + ')';
                    } else {
                        header.style.backgroundColor = null;
                    }
                } else {
                    headerWrapper.style.transform = null;
                    header.style.backgroundColor = null;
                    this.setState({headerFixed: false});
                }

                // set heroContent transform from top
                if (scrollTop <= this.heroHeight) {
                    const paddingToCenter = (scrollTop / (this.heroHeight / HEADER_MIN_HEIGHT)).toFixed(0);
                    const heroTranslate = parseInt((this.heroContentPadding / 2).toFixed(0), 10) + parseInt(paddingToCenter, 10);

                    heroContent.style.transform = "translateY(" + heroTranslate + "px)";
                }

                const startOpacityPosition = heroContent.getBoundingClientRect().top;
                if (scrollTop <= this.heroHeight && startOpacityPosition <= 250) {
                    let elementHeight = contentTop - HEADER_MAX_HEIGHT,
                        calcContent = ((elementHeight - contentTop) / elementHeight) + 1.38;
                        calcContent = calcContent.toFixed(2);
        
                    if (calcContent < 0 || calcContent > 2) {
                        calcContent = 0;
                    }
        
                    heroContent.style.opacity = calcContent;
                }
            }
            
            // Mobile
            if (isMobile) {
                headerWrapper.style.transform = null;
                heroContent.style.opacity = null;

                let newHeight = this.headerHeight - scrollTop;
                let newOpacityMobile = Math.abs(scrollTop / headerInnerHeight).toFixed(2);

                if (newHeight <= HEADER_MIN_HEIGHT) {
                    newHeight = HEADER_MIN_HEIGHT;
                    this.setState({headerFixed: true});
                } else {
                    this.setState({headerFixed: false});
                }

                header.style.height = newHeight + "px";    

                if (newOpacityMobile < 1) {
                    if (newHeight === this.headerHeight && newOpacityMobile < 0.1) {
                        newOpacityMobile = 0;
                    }
                    header.style.backgroundColor = 'rgba(255, 255, 255, ' + newOpacityMobile + ')';
                } else {
                    header.style.backgroundColor = null;
                    header.style.height = null;
                }
            }
        } else {
            this.sectionsRefs.heroMouse.current.classList.remove('is-hidden');
        }

        if (scrollTop <= 0) {
            headerWrapper.style.transform = null;
            header.style.backgroundColor = null;
            header.style.height = null;
            heroContent.style.transform = null;
            heroContent.style.opacity = null;
            
            if (headerFixed) {
                this.setState({headerFixed: false});
            }
        }
    }

    toggleMenu() {
        const menuState = !this.state.menuOpen,
            scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop;
        let data = {menuOpen: menuState, scrollBlockEnabled: menuState};

        if (window.innerWidth > 425) {
            if (scrollPos >= 74) {
                data = {menuOpen: menuState, menuOpenLogoAnimation: menuState, scrollBlockEnabled: menuState}
            }
        } else {
            if (scrollPos >= 49) {
                data = {menuOpen: menuState, menuOpenLogoAnimation: menuState, scrollBlockEnabled: menuState}
            }
        }

        this.setState(data);
    }

    scrollToRef(ref, e, section, scrollOffset = true) {
        e.preventDefault();
        e.target.blur();
        let offset;
        const isMobile = this.state.device === 'mobile';

        const options = {
            duration: 400,
            smooth: true
        }

        if (scrollOffset) {
            offset = isMobile ?  -60 : -40;
        } else {
            offset = 0;
        }

        const scrollTo = ref.current.offsetTop + offset;

        this.setState({mneuActiveItem: section, scrollActive: true}, () => {
            if (this.state.menuOpen) {
                this.toggleMenu();

                animateScroll.scrollTo(scrollTo, options);
            }

            if (this.state.workDetail !== null) {
                this.onCloseWorkDetail();

                setTimeout(() => {
                    animateScroll.scrollTo(scrollTo, options);
                }, 500);
            }

            if (!this.state.menuOpen && this.state.workDetail == null) {
                animateScroll.scrollTo(scrollTo, options);
            }

            if (section) {
                setTimeout(() => {
                    this.setState({scrollActive: false});
                }, 400);
            }
        });
    }

    onOpenWorkDetail = (index) => {
        clearTimeout(this.workTimeout);
        this.setState({scrollBlockEnabled: true, workDetail: index, workCloseDetail: false});
    }

    onCloseWorkDetail = () => {
        this.setState({scrollBlockEnabled: true, workCloseDetail: true});

        this.workTimeout = setTimeout(() => {
            this.setState({scrollBlockEnabled: false, workDetail: null});            
        }, 500);
    }

    onClickServicesItem = (subject, e) => {
        console.log(subject);
        this.setState({contactSubject: subject});
        this.scrollToRef(this.sectionsRefs.contact, e);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 27) { // Escape
            if (!this.state.workCloseDetail) {
                clearTimeout(this.workTimeout);
                this.onCloseWorkDetail();
            }

            if (this.state.menuOpen) {
                this.toggleMenu();
            }
        }
    }

    render() {
        return (
            <ScrollLocky headless gapMode="padding" enabled={this.state.scrollBlockEnabled}>
                {this.state.scrollBlockEnabled &&
                    <style dangerouslySetInnerHTML={{__html: `
                        body {
                            overflow: hidden;
                            -webkit-overflow-scrolling: touch;
                        }   
                    `}} />
                }
                <div className="content">
                    <Header 
                        menuOpen={this.state.menuOpen} 
                        menuOpenLogoAnimation={this.state.menuOpenLogoAnimation}
                        onToggleMenu={() => this.toggleMenu()} 
                        sectionsRefs={this.sectionsRefs} 
                        onClick={(ref, e, section, offset) => this.scrollToRef(ref, e, section, offset)}
                        closeWorkDetail={() => this.onCloseWorkDetail()}
                        activeWorkDetail={this.state.workDetail !== null}
                        gapWidth={this.state.gapWidth}
                        handleKeyDown={this.handleKeyDown}
                        scrollBlockEnabled={this.state.scrollBlockEnabled}
                        headerRef={this.sectionsRefs.header}
                        headerWrapperRef={this.sectionsRefs.headerWrapper}
                        headerFixed={this.state.headerFixed}
                        activeItem={this.state.mneuActiveItem} />

                    <Hero 
                        sectionRef={this.sectionsRefs.hero} 
                        contentRef={this.sectionsRefs.heroContent}
                        mouseRef={this.sectionsRefs.heroMouse} />

                    <Services 
                        sectionRef={this.sectionsRefs.services} 
                        onClick={this.onClickServicesItem}
                    />
                    {/* <About sectionRefs={this.sectionsRefs}
                            onClick={(ref, e) => this.scrollToRef(ref, e)} /> */}
                    <Technology 
                        device={this.state.device} 
                        sectionRef={this.sectionsRefs.technology}
                        deviceWidth={this.state.deviceWidth} />
                    <Work 
                        device={this.state.device}
                        deviceWidth={this.state.deviceWidth}
                        sectionsRefs={this.sectionsRefs} 
                        onOpenDetail={(index) => this.onOpenWorkDetail(index)}
                        closeDetailByMenu={this.state.workCloseDetail}
                        onCloseDetail={this.onCloseWorkDetail}
                        handleKeyDown={this.handleKeyDown} />
                    <Contact 
                        sectionRef={this.sectionsRefs.contact}
                        subject={this.state.contactSubject}
                        device={this.state.device}
                    />
                    <Footer />
                </div>
            </ScrollLocky>
        );
    }
}

export default App;
