import Heading from './Heading';
import CardsItem from './CardsItem';
import { Carousel } from 'react-responsive-carousel';

import IcoSass from 'assets/svg/sass.svg';
import IcoReact from 'assets/svg/react.svg';
import IcoGulp from 'assets/svg/gulp.svg';
import IcoBrowserStack from 'assets/svg/browserstack.svg';
import IcoGitlab from 'assets/svg/gitlab.svg';
import IcoGit from 'assets/svg/git.svg';
import IcoAvocode from 'assets/svg/avocode.svg';
import IcoHTML from 'assets/svg/html5.svg';
import IcoCSS from 'assets/svg/css3.svg';
import IcoPHP from 'assets/svg/php.svg';
import IcoMySQL from 'assets/svg/mysql.svg';
import IcoWebpack from 'assets/svg/webpack.svg';
import IcoJS from 'assets/svg/javascript.svg';
import IcoJquery from 'assets/svg/jquery.svg';
import IcoTypeScript from 'assets/svg/typescript.svg';
import IcoNetlify from 'assets/svg/netlify.svg';
import IcoNextJs from 'assets/svg/next-dot-js.svg';
import classNames from 'classnames';

const items = [
    {
        'items': [
            {
                'icons': [
                    {'src': IcoHTML},
                    {'src': IcoCSS}
                ],
                'title': 'HTML & CSS',
            },
            {
                'icons': [
                    {'src': IcoJS},
                    {'src': IcoJquery}
                ],
                'title': 'Javascript',
                'description': 'Pro dobře udržitelný a strukturovaný kód používám preprocesor Sass.'
            },
            {
                'icons': [
                    {'src': IcoTypeScript}
                ],
                'title': 'TypeScript',
            },
            {
                'icons': [
                    {'src': IcoReact},
                ],
                'title': 'React',
                'description': 'Javascriptovou knihovnu React využívám pro tvorbu Single Page Applications.'
                //'description': 'Single Page Applications tvořím&nbsp;pomocí Javascriptové knihovny React.'
                //'description': 'React mi umožňuje vytvořit Single Page Applications (SPA)'
                //'description': 'Při kódování šablon pro Single Page Applications (SPA) využívám znalosti atomického designu.'
            },
            {
                'icons': [
                    {'src': IcoNextJs},
                ],
                'title': 'Next.js',
                'description': 'Pro dobře udržitelný a strukturovaný kód používám preprocesor Sass.'
            },
            {
                'icons': [
                    {'src': IcoSass}
                ],
                'title': 'Sass',
                'description': 'Pro dobře udržitelný a strukturovaný kód používám preprocesor Sass.'
            },
        ]
    },
    {
        items: [
            // {
            //     'icons': [
            //         {'src': IcoGatsby}
            //     ],
            //     'title': 'Gatsby',
            // },
            {
                'icons': [
                    {'src': IcoGulp},
                    {'src': IcoWebpack}
                ],
                'title': 'Gulp & Webpack',
                'description': 'Gulp používám pro automatizaci procesů, jako je např. kompilování stylů a optimalizace obrázků.'
            },
            {
                'icons': [
                    {'src': IcoGit},
                    {'src': IcoGitlab}
                ],
                'title': 'Git & Gitlab',
                'description': 'Verzování a správu projektů mi zajišťuje Git společně s&nbsp;webovým rozhraním Gitlab.'
            },
            {
                'icons': [
                    // {'src': IcoPHP},
                    {'src': IcoMySQL}
                ],
                'title': 'SQL & MySQL',
            },
            {
                'icons': [
                    {'src': IcoBrowserStack}
                ],
                'title': 'BrowserStack',
                'description': 'BrowserStack používám pro testování webových stránek na různých zařízeních.'
            },
            // {
            //     'icons': [
            //         {'src': IcoAvocode}
            //     ],
            //     'title': 'Avocode',
            // },
            {
            'icons': [
                    {'src': IcoNetlify}
                ],
                'title': 'Netlify',
            },
            // {
            //     'icons': [
            //         {'src': IcoBootstrap},
            //         {'src': IcoAntd}
            //     ],
            //     'title': 'UI frameworks',
            //     'description': 'BEM, OOCSS, WAI-ARIA, SVG, Twig/Latte, Npm, Slack, ...'
            // },
            {
                // 'title': 'UI frameworks',
                'text': 'BEM, OOCSS, ITCSS, WAI-ARIA, SVG, Twig/Latte, ...'
            },
        ]
    },
    // {
    //     items: [
    //         {
    //             'icons': [
    //                 {'src': IcoAvocode}
    //             ],
    //             'title': 'Avocode',
    //             'description': 'Avocode mi usnadňuje práci s&nbsp;grafickými návrhy a&nbsp;celkově urychluje kódování webu.'
    //         },
    //         {
    //             'icons': [
    //                 {'src': IcoVSCode}
    //             ],
    //             'title': 'VS code',
    //         },
    //         {
    //             'icons': [
    //                 {'src': IcoToggl}
    //             ],
    //             'title': 'Toggl',
    //         },
    //         {
    //             'icons': [
    //                 {'src': IcoSlack}
    //             ],
    //             'title': 'Slack',
    //         },
    //     ]
    // },
];

const mobileItems = [];

items.map((slide) => {
    slide.items.map((item) => {
        mobileItems.push(item);
    });
});

class Technology extends React.PureComponent {

    state = {
        currentSlide: 0,
    };

    // componentDidMount() {
    //     this.slider = false;
    //     this.sliderSettings = {
    //         perView: 1,
    //         gap: 0,
    //         startAt: 0,
    //         rewind: false,
    //         perTouch: 0,
    //         bound: true,
    //         touchRatio: 0,
    //     };


    //     if (this.props.device === 'desktop') {
    //         this.slider = new Glide('.slider', this.sliderSettings).mount();
    //     }
    // }

    // componentWillUpdate(nextProps) {
    //     if (nextProps.device !== this.props.device) {
    //         if (nextProps.device === 'desktop') {
    //             // this.slider = new Glide('.slider', this.sliderSettings);
    //         } else {
    //             if (this.slider !== false) {
    //                 // this.slider.destroy();
    //             }
    //         }
    //     }
    // }

    // componentDidUpdate() {
    //     if (this.props.device === 'desktop') {
    //         // this.slider.mount();
    //     }
    // }

    setActiveSlide = (index, e) => {
        e.preventDefault();
        this.setState({currentSlide: index});
    }

    render() {

        const {deviceWidth} = this.props;
        const {currentSlide} = this.state;
        const isMobile = deviceWidth <= 1000

        return (
            <section className="section section--bg-secondary" ref={this.props.sectionRef} id="technology">
                <div className="wrapper">
                    <Heading
                        element='h3'
                        title='Znalosti a nástroje'
                        modifiers='heading--center' />

                        {isMobile ? 
                            <div className="slider">
                                <ul className="slider__inner">
                                {items.map((slide, index) =>
                                    slide.items.map((item, index) => (
                                        <CardsItem
                                            element='li'
                                            icons={item.icons}
                                            title={item.title}
                                            description={item.description}
                                            text={item.text}
                                            key={index} />
                                    ))
                                )}
                                </ul>
                            </div>
                        :  
                            <>
                                <Carousel 
                                    showArrows={false}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                    infiniteLoop
                                    emulateTouch
                                    useKeyboardArrows
                                    selectedItem={this.state.currentSlide}
                                    swipeable={deviceWidth > 1000}
                                    onChange={(index) =>
                                        this.setState({currentSlide: index})
                                    }>
                                    {items.map((slide, index) => (
                                        <ul className="slider__item" key={index}>
                                            {slide.items.map((item, index) => (
                                                <CardsItem
                                                    element='li'
                                                    icons={item.icons}
                                                    title={item.title}
                                                    description={item.description}
                                                    text={item.text}
                                                    key={index} />
                                            ))}
                                        </ul>) 
                                    )}
                                </Carousel>

                                <ul className="slider__dots">
                                    {items.map((item, index) => (
                                        <li className={classNames('slider__dots-item', currentSlide === index && 'is-active')} key={index}>
                                            <a href="#" className="slider__dots-item-link" onClick={(e) => this.setActiveSlide(index, e)}>
                                                <span className="u-vishid">Stránka {index}</span>
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        }
                </div>
            </section>
        )
    }
}

export default Technology