import IcoSearch from 'assets/svg/fullscreen.svg';
import IcoLink from 'assets/svg/link.svg';
import Loader from './Loader';
import {throttle} from 'throttle-debounce';

class WorkItem extends React.PureComponent {
	imageWidth = 0;

	state = {
		detailReady: false,
		imageLoaded: false,
		thumbImageLoaded: false,
		detailLoaded: false,
		showImage: false,
		isClickable: false,
	}

	componentDidMount() {
		this.imageWidth = window.innerWidth > 1170 ? 1170 : window.innerWidth;
	}

	componentDidUpdate() {
		const props = this.props;

		if (props.activeDetail && !this.state.detailLoaded) {
			this.setState({detailLoaded: true});
			this.refs.modal.focus();

			this.refs.modal.addEventListener('scroll', this.handleScroll);
		}
	}

	componentWillReceiveProps(props) {
		if (props.itemRef.currentDOM && !this.state.detailReady) {
			this.setState({detailReady: true});
		}
	}

	componentWillUpdate(nextProps) {
		if (this.props.detailShow !== nextProps.detailShow && this.state.detailReady) {
			this.setState({detailLoaded: false, showImage: false});
		}
	}

	onCloseDetail = () => {
		clearTimeout(this.timeout);
		const props = this.props;
		props.closeDetail();
		props.onCloseDetail();
		this.refs.modal.removeEventListener('scroll', this.handleScroll);
	}

	onLoadImage = () => {
		clearTimeout(this.timeout);
		this.setState({imageLoaded: true});

		this.timeout = setTimeout(() => {
			this.setState({showImage: true});
			this.props.headerRef.classList.add('work-detail-active');
		}, 1500); // simulate server latency
	}

	onLoadThumbImage = () => {
		this.props.onLoadItemImage();
		this.setState({thumbImageLoaded: true});

		setTimeout(() => {
			this.setState({isClickable: true});			
		}, 700);
	}

	onClickLink = () => {
		this.linkToWeb.blur();
	}

	handleScroll = () => {
		throttle(100, this.shadowHandler());
	}

	shadowHandler = () => {
        const scrollTop = this.refs.modal.scrollTop ||   this.refs.modal.pageYOffset;

		if (scrollTop > 0) {
			this.props.headerRef.classList.add('has-shadow');
		} else {
			this.props.headerRef.classList.remove('has-shadow');
		}
	}
	
	render () { 
		const {
			title, 
			description, 
			thumbnailName, 
			detailImgSrc, 
			link,
			linkText,
			onClick, 
			openDetail, 
			activeDetail, 
			itemRef,  
			handleKeyDown,
			device,
			hoverActive,
			detailReady,
			detailShow,
			deviceWidth
		} = this.props;

		const {imageLoaded, showImage, isClickable, thumbImageLoaded} = this.state;
		const imageWidth = this.imageWidth;

		return (
			<div className={`work__item${thumbImageLoaded ? ' is-loaded' : ''}${isClickable ? ' is-clickable' : ''}`} onClick={this.state.isClickable && device !== 'desktop' && hoverActive === 'null' ? onClick : null}>
				<div className={`work__item-inner${detailReady ? ' is-active' : ''}`} ref={itemRef} onClick={device === 'desktop' && !detailShow ? openDetail : null}>
					<div onKeyDown={activeDetail ? (e) => handleKeyDown(e) : null}
						tabIndex="0"
						ref="modal"
						className={`work__item-overlay${detailReady ? ' is-ready' : ''}${detailShow ? ' is-open' : ''}${detailShow && showImage && imageLoaded ? ' is-scrollable' : ''}`} 
						style={activeDetail ? {position: "fixed", top: itemRef.currentDOM.top, left: itemRef.currentDOM.left, width: itemRef.currentDOM.width, height: itemRef.currentDOM.height} : null}>
						<div className={`work__item-overlay-wrapper${detailShow && showImage && imageLoaded ? ' is-hidden' : ''}`}>
							<div className="work__item-overlay-inner">
								<button type="button" className={`work__item-icon ${(!imageLoaded || !showImage) && detailShow ? ' is-loading' : ''}`} onClick={device !== 'desktop' ? openDetail : null}>
								{(!imageLoaded || !showImage) && detailShow ? 
									<Loader />	
									:
									<IcoSearch width="18" height="18" />
								}
								</button>
								<p className="work__item-title">{title}</p>
								<p className="work__item-description" dangerouslySetInnerHTML={{__html: description}}></p>
							</div>
						</div>
						{activeDetail && detailShow && detailReady && 
							<div className={`work__item-detail${showImage && imageLoaded ? ' is-loaded' : ''}`}>
								<div className="work__item-detail-wrapper wrapper">
									<div className="work__item-detail-header">
										<div className="heading">
											<h1 className="heading__title">{title}</h1>
											<p className="heading__subtitle">{description}</p>
											{link &&
												<p className="work__item-detail-url">
													<a href={link} 
														target="_blank" 
														className="work__item-detail-link" 
														onClick={() => this.onClickLink()} 
														ref={(ref) => this.linkToWeb = ref}
														rel="noopener noreferrer">
														<IcoLink className="work__item-detail-link-icon" width="16" height="16" />
														<span className="work__item-detail-link-text">{linkText}</span>
													</a>
												</p>
											}
										</div>
										<button type="button" className="header__menu-toggle header__menu-toggle--detail" onClick={this.onCloseDetail}>
											<span className="u-vishid">Zavřít detail</span>
										</button>
									</div>
									<div className="work__item-detail-image">
										<picture>
											<source type="image/webp" srcSet={`https://res.cloudinary.com/dunzgcpfq/image/upload/c_scale,f_webp,q_auto,w_${imageWidth}/${detailImgSrc}.webp 1x, https://res.cloudinary.com/dunzgcpfq/image/upload/c_scale,dpr_2.0,f_webp,q_auto,w_${imageWidth}/${detailImgSrc}.webp 2x`} />
											<img src={`https://res.cloudinary.com/dunzgcpfq/image/upload/c_scale,q_auto,w_${imageWidth}/${detailImgSrc}.jpg`} 
												srcSet={`https://res.cloudinary.com/dunzgcpfq/image/upload/c_scale,q_auto,w_${imageWidth}/${detailImgSrc}.jpg 1x, https://res.cloudinary.com/dunzgcpfq/image/upload/c_scale,dpr_2.0,q_auto,w_${imageWidth}/${detailImgSrc}.jpg 2x`}
												onLoad={() => this.onLoadImage()}
												alt={title} />
										</picture>
									</div>
								</div>
							</div>
						}
					</div>
					<div className={`work__item-image${detailShow ? ' is-zoomed' : ''}`}>
							<picture>
								<source type="image/webp" srcSet={`https://res.cloudinary.com/dunzgcpfq/image/upload/t_thumb-1x/${detailImgSrc}.webp 1x, https://res.cloudinary.com/dunzgcpfq/image/upload/t_thumb-2x/${detailImgSrc}.webp 2x`} onLoad={this.onLoadThumbImage.bind(this)} />
								<img src={`https://res.cloudinary.com/dunzgcpfq/image/upload/t_Thumb-1x/${detailImgSrc}.jpg`} 
									srcSet={`https://res.cloudinary.com/dunzgcpfq/image/upload/t_Thumb-1x/${detailImgSrc}.jpg 1x, https://res.cloudinary.com/dunzgcpfq/image/upload/t_Thumb-2x/${detailImgSrc}.jpg 2x`}
									onLoad={this.onLoadThumbImage.bind(this)} 
									alt={`Náhled webu - ${title}`}
									width={370}
									height={270}
									loading="lazy"
								/>
							</picture>
					</div>
				</div>
			</div>
		)
	}
}

export default WorkItem