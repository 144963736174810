import IcoLinkedIn from 'assets/svg/linkedin.svg';
import IcoTwitter from 'assets/svg/twitter-alt.svg';

const Social = () => (
    <nav className="social">
        <a href="https://www.linkedin.com/in/tomasbohdalek" target="_blank" rel="noopener noreferrer" className="social__item">
            <span className="u-vishid">LinkedIn</span>
            <IcoLinkedIn className="social__item-icon" width="18" height="16" />
        </a>
        <a href="https://twitter.com/tomasbohdalek" target="_blank" rel="noopener noreferrer" className="social__item">
            <span className="u-vishid">Twitter</span>
            <IcoTwitter className="social__item-icon" width="18" height="20" />           
        </a>
    </nav>
)

export default Social