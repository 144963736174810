import React from 'react';
import classNames from 'classnames';

const Hero = ({sectionRef, contentRef, mouseRef}) =>
    <section className="hero" ref={sectionRef}>
        <div className="hero__wrapper wrapper">
            <div className="hero__content" ref={contentRef}>
                <h1 className="hero__content-title">Tomáš Bohdálek</h1>
                <p className="hero__content-subtitle">Frontend kodér / vývojář</p>
            </div>
            <div className="hero__mouse" ref={mouseRef}></div>
        </div>
    </section>

export default Hero
