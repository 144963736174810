import React from 'react';
import Heading from './Heading';
import WorkItem from './WorkItem';
import Loader from './Loader';

// import ImgNostresscare from 'assets/img/work/nostresscare.jpg';
// import ImgVhaji from 'assets/img/work/ubytovanivhaji.jpg';
// import ImgUlovzakaznika from 'assets/img/work/ulovzakaznika.jpg';
// import ImgMagicaltravelling from 'assets/img/work/magicaltravelling.jpg';
// import ImgZdruheruky from 'assets/img/work/zdruheruky.jpg';
// import ImgSkrblik from 'assets/img/work/skrblik.jpg';
// import ImgBankguru from 'assets/img/work/bankguru.jpg';
// import ImgBonusuhypoteky from 'assets/img/work/bonusuhypoteky.jpg';
// import ImgCeskasportielna from 'assets/img/work/ceskasporitelna.jpg';
// import ImgMonosh from 'assets/img/work/monosh.jpg';
// import ImgTipli from 'assets/img/work/tipli.jpg';
// import ImgWildthing from 'assets/img/work/wildthing.jpg';

/* import 'assets/img/work/nostresscare-thumb.jpg';
import 'assets/img/work/nostresscare-thumb@2x.jpg';
import 'assets/img/work/nostresscare-thumb.webp';
import 'assets/img/work/nostresscare-thumb@2x.webp';

import 'assets/img/work/ubytovanivhaji-thumb.jpg';
import 'assets/img/work/ubytovanivhaji-thumb@2x.jpg';
import 'assets/img/work/ubytovanivhaji-thumb.webp';
import 'assets/img/work/ubytovanivhaji-thumb@2x.webp';

import 'assets/img/work/ulovzakaznika-thumb.jpg';
import 'assets/img/work/ulovzakaznika-thumb@2x.jpg';
import 'assets/img/work/ulovzakaznika-thumb.webp';
import 'assets/img/work/ulovzakaznika-thumb@2x.webp';

import 'assets/img/work/magicaltravelling-thumb.jpg';
import 'assets/img/work/magicaltravelling-thumb@2x.jpg';
import 'assets/img/work/magicaltravelling-thumb.webp';
import 'assets/img/work/magicaltravelling-thumb@2x.webp';

import 'assets/img/work/zdruheruky-thumb.jpg';
import 'assets/img/work/zdruheruky-thumb@2x.jpg';
import 'assets/img/work/zdruheruky-thumb.webp';
import 'assets/img/work/zdruheruky-thumb@2x.webp';

import 'assets/img/work/skrblik-thumb.jpg';
import 'assets/img/work/skrblik-thumb@2x.jpg';
import 'assets/img/work/skrblik-thumb.webp';
import 'assets/img/work/skrblik-thumb@2x.webp';

import 'assets/img/work/bankguru-thumb.jpg';
import 'assets/img/work/bankguru-thumb@2x.jpg';
import 'assets/img/work/bankguru-thumb.webp';
import 'assets/img/work/bankguru-thumb@2x.webp';

import 'assets/img/work/bonusuhypoteky-thumb.jpg';
import 'assets/img/work/bonusuhypoteky-thumb@2x.jpg';
import 'assets/img/work/bonusuhypoteky-thumb.webp';
import 'assets/img/work/bonusuhypoteky-thumb@2x.webp';

import 'assets/img/work/ceskasporitelna-thumb.jpg';
import 'assets/img/work/ceskasporitelna-thumb@2x.jpg';
import 'assets/img/work/ceskasporitelna-thumb.webp';
import 'assets/img/work/ceskasporitelna-thumb@2x.webp';

import 'assets/img/work/monosh-thumb.jpg';
import 'assets/img/work/monosh-thumb@2x.jpg';
import 'assets/img/work/monosh-thumb.webp';
import 'assets/img/work/monosh-thumb@2x.webp';

import 'assets/img/work/tipli-thumb.jpg';
import 'assets/img/work/tipli-thumb@2x.jpg';
import 'assets/img/work/tipli-thumb.webp';
import 'assets/img/work/tipli-thumb@2x.webp';

import 'assets/img/work/wildthing-thumb.jpg';
import 'assets/img/work/wildthing-thumb@2x.jpg';
import 'assets/img/work/wildthing-thumb.webp';
import 'assets/img/work/wildthing-thumb@2x.webp';
 */

const PAGINATION_DESKTOP = 6;
const PAGINATION_TABLET = 4;
const PAGINATION_MOBILE = 3;

const workItems = [
    {
        'title': 'Nazuby.cz',
        'description': 'Kódování šablon a vývoj v Proof & Reason',
        'detailImgSrc': 'v1600100319/nazuby-cz',
        'link': 'https://www.nazuby.cz/',
        'linkText': 'nazuby.cz'
    },
    {
        'title': 'V Háji',
        'description': 'Kompletní tvorba webových stránek',
        'detailImgSrc': 'v1566491834/vhaji_g0qibl',
        'link': 'https://ubytovanivhaji.cz/',
        'linkText': 'ubytovanivhaji.cz'
    },
    {
        'title': 'Jan Junek',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491834/janjunek_bweg18',
        'link': 'https://janjunek.com/',
        'linkText': 'janjunek.com'
    },
    {
        'title': 'Café Datel',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491834/cafedatel_vrpl3k',
        'link': 'https://cafedatel.cz/',
        'linkText': 'cafedatel.cz'
    },
    {
        'title': 'Hvozd gin 2020',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1644531499/hvozdgin-cz',
        'link': 'https://hvozdgin.cz/',
        'linkText': 'hvozdgin.cz'
    },
    {
        'title': 'Hvozd gin 2019',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491834/hvozdgin_ovx7hd',
        'link': 'https://hvozdgin.cz/2019',
        'linkText': 'hvozdgin.cz/2019'
    },
    {
        'title': 'Nostresscare',
        'description': 'Kódování šablon',
        'detailImgSrc': 'v1566492112/nostresscare_fh1k5s',
        'link': 'https://nostresscare.cz/',
        'linkText': 'nostresscare.cz'
    },
    {
        'title': 'Ulov Zákazníka',
        'description': 'Kompletní tvorba webových stránek',
        'detailImgSrc': 'v1566491840/ulovzakaznika_c7w4je',
        'linkText': 'ulovzakaznika.cz',
        'link': 'http://ulovzakaznika.cz',
    },
    {
        'title': 'Slevato',
        'description': 'Kódování šablon',
        'detailImgSrc': 'v1566502559/slevato_mliifl',
        'linkText': 'slevato.cz',
        'link': 'http://slevato.cz',
    },
    {
        'title': 'Skrblík kupóny',
        'description': 'Kódování šablon',
        'detailImgSrc': 'v1566501499/skrblik_n9paia',
        'link': 'https://www.skrblik.cz/kupony/',
        'linkText': 'skrblik.cz/kupony',
    },
    {
        'title': 'Bankguru',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491808/bankguru_w23lap',
        'link': 'https://www.bankguru.co.in/',
        'linkText': 'bankguru.co.in',
    },
    {
        'title': 'DPH firmy',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491812/dph-firmy_eayt37',
        'link': 'https://dphfirmy.cz/',
        'linkText': 'dphfirmy.cz',
    },
    {
        'title': 'Magical travelling',
        'description': 'Kódování šablon',
        'detailImgSrc': 'v1566502749/magicaltravelling_wnt6rs',
        'link': 'https://magicaltravelling.com/',
        'linkText': 'magicaltravelling.com',
    },
    {
        'title': 'Bonus u hypotéky',
        'description': 'Kódování šablon',
        'detailImgSrc': 'v1566502559/bonusuhypoteky_skw9h2',
    },
    {
        'title': 'Tipli',
        'description': 'Kódování šablony',
        'detailImgSrc': 'v1566491796/tipli_hkscf2',
    },
    {
        'title': 'Wildthing',
        'description': 'Kompletní tvorba webových stránek',
        'detailImgSrc': 'v1566501321/wildthing_wqdemy',
        'link': 'http://wildthing.cz',
        'linkText': 'wildthing.cz',
    },
    {
        'title': 'Z druhé ruky',
        'description': 'Kompletní tvorba webových stránek',
        'detailImgSrc': 'v1566502556/zdruheruky_moth3a',
        'link': 'https://www.zdruheruky.cz',
        'linkText': 'zdruheruky.cz',
    },
]

class Work extends React.PureComponent {

    state = {
        hoverItem: null,
        activeDetail: null,
        detailReady: null,
        detailShow: null,
        pagination: this.props.device === 'mobile' ? PAGINATION_MOBILE : PAGINATION_DESKTOP,
        loaded: false,
        loading: false,
    }

    itemRefs = [];

    pagination = null;
    loadCount = 0;

    componentWillReceiveProps(props) {
        if (this.pagination !== null) {
            if (props.deviceWidth > 768) {
                this.pagination = PAGINATION_DESKTOP;
            } else if (props.deviceWidth <= 768 && props.deviceWidth > 425) {
                this.pagination = PAGINATION_TABLET;
            } else {
                this.pagination = PAGINATION_MOBILE;
            }
        }

        workItems.map((item, index) => {
            this.itemRefs.push({current: React.createRef()});
        });
        
        if (props.closeDetailByMenu) {
            this.closeDetail();
        }
    }

    componentDidMount() {
        if (this.props.deviceWidth > 768) {
            this.pagination = PAGINATION_DESKTOP;
        } else if (this.props.deviceWidth <= 768 && this.props.deviceWidth > 425) {
            this.pagination = PAGINATION_TABLET;
        } else {
            this.pagination = PAGINATION_MOBILE;
        }
    }

    componentWillUpdate(nextProps) {
        if (nextProps.deviceWidth !== this.props.deviceWidth) {
            if (nextProps.deviceWidth > 768) {
                this.setState({pagination: PAGINATION_DESKTOP});
                this.pagination = PAGINATION_DESKTOP;
            } else if (nextProps.deviceWidth <= 768 && nextProps.deviceWidth > 425) {
                this.setState({pagination: PAGINATION_TABLET});
                this.pagination = PAGINATION_TABLET;
            } else {
                this.setState({pagination: PAGINATION_MOBILE});
                this.pagination = PAGINATION_MOBILE;
            }
        }
    }

    toggleActiveItem = (index) => {
        this.setState({hoverItem: index});
    }

    openDetail = (index) => {
        clearTimeout(this.closeTimeoutShow);
        clearTimeout(this.closeTimeoutReady);

        if (this.openDetailFrame) {
            window.cancelAnimationFrame(this.openDetailFrame);
        }

        this.openDetailFrame = window.requestAnimationFrame(() => {
            this.props.onOpenDetail(index);
            this.itemRefs[index].currentDOM = this.itemRefs[index].current.getBoundingClientRect();
            this.setState({activeDetail: index});
    
            this.openTimeoutReady = setTimeout(() => {
                this.setState({detailReady: index});
            }, 20);
    
            this.openTimeoutShow = setTimeout(() => {
                this.setState({detailShow: index});
            }, 30);
        });
    }

    closeDetail = () => {
        clearTimeout(this.openTimeoutReady);
        clearTimeout(this.openTimeoutShow);

        if (this.closeDetailFrame) {
            window.cancelAnimationFrame(this.closeDetailFrame);
        }

        this.closeDetailFrame = window.requestAnimationFrame(() => {
            this.setState({detailShow: null});

            this.closeTimeoutReady = setTimeout(() => {
                this.setState({detailReady: null, activeDetail: null, hoverItem: null});
            }, 450);
        })
    }

    onLoadItemImage = (index) => {
        this.loadCount = this.loadCount + 1;
        if (this.loadCount === this.pagination || (workItems.length - 1 === index)) {
            this.setState({loading: false});
            this.loadCount = 0;
        } else {
            return
        }
    }

    loadMore = () => {
        this.buttonLoadMoreDOM.blur();

        this.setState({loading: true});

        setTimeout(() => {
            if (this.props.deviceWidth > 768) {
                this.setState({pagination: this.state.pagination + PAGINATION_DESKTOP});
            } else if (this.props.deviceWidth <= 768 && this.props.deviceWidth > 425) {
                this.setState({pagination: this.state.pagination + PAGINATION_TABLET});
            } else {
                this.setState({pagination: this.state.pagination + PAGINATION_MOBILE});
            }

            this.setState({loading: true});
        }, 200);
    }

    onLoadImage = () => {
        if (this.itemRefs.length > 0) {
            this.setState({loaded: true});
        }
    }

    render() {

        const pagination = this.state.pagination;

        return (
            <section className="section" ref={this.props.sectionsRefs.work} id="work">
                <div className="wrapper">
                    <Heading
                        element="h4"
                        title="Reference"
                        modifiers="heading--center" />

                    <div className="work__item-wrapper">
                        {workItems.map((item, index) =>
                            index < pagination ?
                            <WorkItem
                                itemRef={this.itemRefs[index]}
                                title={item.title}
                                description={item.description}
                                detailImgSrc={item.detailImgSrc}
                                thumbnailName={item.thumbnailName}
                                link={item.link}
                                linkText={item.linkText}
                                onLoadImage={this.onLoadImage}
                                key={index}
                                loaded={this.state.loaded}
                                hoverActive={this.state.hoverItem === index}
                                activeDetail={this.state.activeDetail === index}
                                detailReady={this.state.detailReady === index}
                                detailShow={this.state.detailShow === index}
                                onClick={() => this.toggleActiveItem(index)}
                                openDetail={() => this.openDetail(index)}
                                closeDetail={() => this.closeDetail()} 
                                onCloseDetail={this.props.onCloseDetail}
                                handleKeyDown={this.props.handleKeyDown}
                                device={this.props.device}
                                onLoadItemImage={() => this.onLoadItemImage(index)}
                                headerRef={this.props.sectionsRefs.header.current}
                                deviceWidth={this.props.deviceWidth} />
                            : null
                        )}
                    </div>
                    <div className="work__load-more">
                        {this.state.loading ?
                            <div className="loader__wrapper"><Loader /></div>
                            :
                            workItems.length > pagination &&
                            <button type="button" className="button button--filled" onClick={() => this.loadMore()} ref={ref => this.buttonLoadMoreDOM = ref}>Zobrazit další</button>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default Work