exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../fonts/montserrat-regular.woff2"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../fonts/montserrat-regular.woff"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../fonts/montserrat-medium.woff2"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../fonts/montserrat-medium.woff"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../../fonts/montserrat-semibold.woff2"));
var ___CSS_LOADER_URL___5___ = urlEscape(require("../../fonts/montserrat-semibold.woff"));
var ___CSS_LOADER_URL___6___ = urlEscape(require("../../fonts/montserrat-bold.woff2"));
var ___CSS_LOADER_URL___7___ = urlEscape(require("../../fonts/montserrat-bold.woff"));
var ___CSS_LOADER_URL___8___ = urlEscape(require("../../fonts/opensans-regular.woff2"));
var ___CSS_LOADER_URL___9___ = urlEscape(require("../../fonts/opensans-regular.woff"));

// Module
exports.push([module.id, "@font-face {\n    font-family: 'Montserrat';\n    font-style: normal;\n    font-weight: 400;\n    font-display: fallback;\n    src: local('Montserrat Regular'), \n        local('Montserrat-Regular'), \n        url(" + ___CSS_LOADER_URL___0___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___1___ + ") format('woff');\n}\n\n@font-face {\n    font-family: 'Montserrat';\n    font-style: normal;\n    font-weight: 500;\n    font-display: fallback;\n    src: local('Montserrat Medium'), \n        local('Montserrat-Medium'), \n        url(" + ___CSS_LOADER_URL___2___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___3___ + ") format('woff');\n}\n\n@font-face {\n    font-family: 'Montserrat';\n    font-style: normal;\n    font-weight: 600;\n    font-display: fallback;\n    src: local('Montserrat SemiBold'), \n        local('Montserrat-SemiBold'), \n        url(" + ___CSS_LOADER_URL___4___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___5___ + ") format('woff');\n}\n\n@font-face {\n    font-family: 'Montserrat';\n    font-style: normal;\n    font-weight: 700;\n    font-display: fallback;\n    src: local('Montserrat Bold'), \n        local('Montserrat-Bold'), \n        url(" + ___CSS_LOADER_URL___6___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___7___ + ") format('woff');\n}\n\n@font-face {\n    font-family: 'Open Sans';\n    font-style: normal;\n    font-weight: 400;\n    font-display: fallback;\n    src: local('Open Sans Regular'), \n        local('OpenSans-Regular'), \n        url(" + ___CSS_LOADER_URL___8___ + ") format('woff2'),\n        url(" + ___CSS_LOADER_URL___9___ + ") format('woff');\n}", ""]);

