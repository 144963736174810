import React from 'react';

const Heading = (props, {Element = props.element}) => (
	<div className={`heading ${props.modifiers ? props.modifiers : ''}`}>
		<Element className="heading__title">{props.title}</Element>
		{props.subtitle &&
			<p className="heading__subtitle">{props.subtitle}</p>
		}
	</div>
)

export default Heading