import Heading from './Heading'
import IcoMobile from 'assets/svg/mobile.svg'
import IcoEmail from 'assets/svg/email.svg'
import IcoMap from 'assets/svg/map-alt.svg'

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

class Contact extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            submited: '', 
            name: "", 
            email: "",
            subject: this.props.subject,
            message: ""
        };
    }

    handleSubmit = e => {
        fetch("/", {
            method: "POST",
            headers: {"Content-Type": "application/x-www-form-urlencoded"},
            body: encode({ "form-name": "contact", ...this.state })
        })
            .then(() => this.setState({submited: 'success', name: "", email: "", subject: "", message: ""}))
            .catch(error => this.setState({submited: error}));

        e.preventDefault();
    };

    componentDidUpdate(prevProps) {
        if (prevProps.subject !== this.props.subject) {
            this.setState({subject: this.props.subject});
        }
    }

    handleChange = e => {
        this.setState({[e.target.name]: e.target.value})
    };

    render() {

        const {submited, name, email, subject, message} = this.state;
        const isMobile = this.props.device === 'mobile';
        
        return (
            <section className="section section--bg-secondary contact" ref={this.props.sectionRef} id="contact">
                <div className="wrapper">
                    <Heading
                        element='h5'
                        title='Máte zájem o spolupráci?'
                        modifiers='heading--center'
                        // subtitle='Kontaktujte mě zprávou, e-mailem nebo telefonicky.'
                    />

                    <div className="contact__box">
                        <div className="contact__item-wrapper">
                            <div className="contact__item">
                                <a href="tel:+420777066573" className="contact__item-link">
                                    <div className="contact__item-icon">
                                        <IcoMobile width="24" height="25" />
                                    </div>
                                    <p className="contact__item-text">+420 777 066 573</p>
                                </a>
                            </div>
         
                                <div className="contact__item">
                                    <div className="contact__item-link">
                                        <div className="contact__item-icon">
                                            <IcoMap width="26" height="19" />
                                        </div>
                                        <p className="contact__item-text">Brno, Litomyšl</p>
                                    </div>
                                </div>
                            
                            <div className="contact__item">
                                <a href="mailto:info@tomasbohdalek.cz" className="contact__item-link">
                                    <div className="contact__item-icon">
                                        <IcoEmail width="26" height="19" />
                                    </div>
                                    <p className="contact__item-text">info@tomasbohdalek.cz</p>
                                </a>
                            </div>
                        </div>
                        <form className="contact__form" onSubmit={this.handleSubmit}>
                            <div className="contact__form-left">
                                <div className="form-item">
                                    <label htmlFor="name" className="u-vishid">Vaše jméno</label>
                                    <input type="text" className="form-item__input" name="name" id="name" value={name} placeholder="Vaše jméno" onChange={this.handleChange} required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="email" className="u-vishid">Váš e-mail</label>
                                    <input type="email" className="form-item__input" name="email" id="email" value={email} placeholder="Váš e-mail" onChange={this.handleChange} required />
                                </div>
                                <div className="form-item">
                                    <label htmlFor="subject" className="u-vishid">Předmět</label>
                                    <input type="text" className="form-item__input" name="subject" id="subject" value={subject} placeholder="Předmět" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="contact__form-right">
                                <label htmlFor="message" className="u-vishid">Obsah zprávy...</label>
                                <textarea className="form-item__input" name="message" id="message" rows="3" value={message} placeholder="Obsah zprávy..." onChange={this.handleChange} required></textarea>
                            </div>
                            <div className="contact__form-footer">
                                {submited &&
                                    <div className="contact__form-message">
                                        {submited === 'success' ?
                                        <p className="contact__form-message-text contact__form-message-text--success">Zpráva byla úspěšně odeslána.</p>
                                        :
                                        <p className="contact__form-message-text contact__form-message-text--error">{submited}</p>
                                    }
                                    </div>
                                }
                                <div className="contact__form-submit">
                                    <button type="submit" className="button button--filled" onClick={() => this.submitButton.blur()} ref={(ref) => this.submitButton = ref}>Odeslat</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contact