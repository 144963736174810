import React from 'react';
import Social from './Social';

const currentYear = new Date().getFullYear();

const Footer = () => (
    <footer className="footer">
        <div className="wrapper">
            <div className="footer__social">
                <Social />
            </div>
            <p className="footer__copy">&copy; 2014 - {currentYear} Tomáš Bohdálek, IČ:&nbsp;05510821</p>
        </div>
    </footer>
)

export default Footer