import Heading from './Heading';
import React from 'react';

const About = (props) => (
    <section className="section" ref={props.sectionRefs.about}>
        <div className="wrapper">
            <div className="about">
                <div className="about__content">
                    <Heading
                        element='h2'
                        title='O mně'
                        modifiers="heading--small-gap"
                    />
                    <p className="about__content-text">
                       Jsem frontend kodér a&nbsp;vývojář na volné noze. Webové tvorbě a kodéřině se aktuálně věnuji 5 let. Za tuto dobu jsem získal mnoho zkušeností a&nbsp;znalostí, které Vám mohu nabídnout při realizaci Vašeho projektu. Nabízím kvalitu, spolehlivost a cit pro detail.
                    </p>
                    <p className="about__content-text">
                        Ve volném čase se zajímám o technologie, sleduji aktuální trendy a tančím latinsko-americké tance.
                    </p>
                    <div className="about__content-footer">
                        <button type="button" className="button button--filled" onClick={(e) => props.onClick(props.sectionRefs.contact, e)}>Kontakt</button>
                    </div>
                </div>
                <div className="about__services">
                    <div className="about__services-card">
                        <p className="about__services-card-title">Frontend kódování</p>
                        <p className="about__services-card-text">Převod grafického návrhu do dobře strukturovaných, responzivních a&nbsp;optimalizovaných HTML/CSS šablon.</p>
                    </div>
                    <div className="about__services-card">
                        <p className="about__services-card-title">Tvorba webových stránek</p>
                        <p className="about__services-card-text">Vytvoření kompletních webových stránek, umístění na server a&nbsp;veškeré nastavení. Následná údržba a&nbsp;další vývoj.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default About