import React from 'react';

class CardsItem extends React.PureComponent {
	render() {

		const Element = this.props.element ? this.props.element : 'div';
		const {icons, title, description, text} = this.props;

		return (
			<Element className="cards__item">
				<div className="cards__item-inner">
					{text ? 
						<p className="cards__item-text">{text}</p>
					: icons && icons.map((icon, index) => (
						<icon.src className="cards__item-icon" width="36" height="36" key={index} />
					))}
					{title &&
						<p className="cards__item-title">{title}</p>
					}
					{/*
					<p className="cards__item-description" dangerouslySetInnerHTML={{__html:description}} />
					*/}
				</div>
			</Element>
		)
	}
}

export default CardsItem