import React from 'react';
import Social from './Social';
import classNames from 'classnames';

class Header extends React.PureComponent {
    render() {    
        const {
            menuOpen,
            menuOpenLogoAnimation,
            onToggleMenu,
            sectionsRefs,
            onClick,
            activeWorkDetail,
            scrollBlockEnabled,
            closeWorkDetail,
            gapWidth,
            handleKeyDown,
            headerRef,
            headerWrapperRef,
            headerFixed,
            activeItem,
        } = this.props;

        const navItemClass = (index) => classNames('header__main-nav-item', index === activeItem && 'is-active')

        return (
            <header className={
                classNames('header', 
                    headerFixed && 'is-fixed', 
                    activeWorkDetail && 'work-detail-active'
                )}
                onKeyDown={(e) => handleKeyDown(e)} 
                ref={headerRef}>
                <div className="header__wrapper wrapper" ref={headerWrapperRef}>
                    <a href="/" onClick={(e) => onClick(sectionsRefs.hero, e, 'hero', false)} className={`header__logo${menuOpen ? ' is-fixed' : ''}${menuOpenLogoAnimation ? ' has-open-animation' : ''}`}>TB</a>
                    <button type="button" className={`header__menu-toggle${menuOpen || activeWorkDetail ? ' is-open' : ''}`} onClick={activeWorkDetail ? closeWorkDetail : onToggleMenu}>
                        {menuOpen && !activeWorkDetail ?
                            <span className="u-vishid">Zavřít menu</span>
                        :
                            <span className="u-vishid">Otevřít menu</span>
                        }
                        {activeWorkDetail &&
                            <span className="u-vishid">Zavřít detail</span>
                        }
                    </button>
                    {scrollBlockEnabled &&
                        <style dangerouslySetInnerHTML={{__html: `
                            .hero__content, .hero__mouse, .header__menu-social {right: ${gapWidth}px }
                            .header__menu-inner {margin-right: ${gapWidth}px }
                            ${activeWorkDetail ? '.work__item-overlay {padding-right:' + gapWidth + 'px} .work__item-overlay.is-scrollable {padding-right: 0} .work__item-overlay.is-scrollable .work__item-overlay-inner {margin-right: -'+ gapWidth +'px}' : ''}
                            ${activeWorkDetail ? '.header {right:' + gapWidth + 'px} .work__item-overlay.is-open {right:' + gapWidth + 'px!important}' : ''}
                        `}} />
                    }

                    <nav className="header__main-nav">
                        <a href="#" className={navItemClass('hero')} onClick={(e) => onClick(sectionsRefs.hero, e, 'hero', false)}>Úvod</a>
                        <a href="#services" className={navItemClass('services')} onClick={(e) => onClick(sectionsRefs.services, e, 'services')}>Služby</a>
                        <a href="#technology" className={navItemClass('technology')} onClick={(e) => onClick(sectionsRefs.technology, e, 'technology')}>Znalosti</a>
                        <a href="#work" className={navItemClass('work')} onClick={(e) => onClick(sectionsRefs.work, e, 'work')}>Reference</a>
                        <a href="#contact" className={navItemClass('contact')} onClick={(e) => onClick(sectionsRefs.contact, e, 'contact')}>Kontakt</a>
                    </nav>
                </div>
       
                <div className={`header__menu ${menuOpen ? 'is-open' : ''}`}>
                    <div className="header__menu-inner">
                        <nav className="header__nav">
                            <a href="#" className="header__nav-item" onClick={(e) => onClick(sectionsRefs.services, e)}>Služby</a>
                            <a href="#" className="header__nav-item" onClick={(e) => onClick(sectionsRefs.technology, e)}>Znalosti</a>
                            <a href="#" className="header__nav-item" onClick={(e) => onClick(sectionsRefs.work, e)}>Reference</a>
                            <a href="#" className="header__nav-item" onClick={(e) => onClick(sectionsRefs.contact, e)}>Kontakt</a>
                        </nav>
                        <div className="header__menu-social">
                            <Social />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
