import Heading from './Heading';
import ServicesItem from './ServicesItem';
import IcoWebsite from 'assets/svg/website.svg';
import IconCode from 'assets/svg/code.svg';
import IcoStatsUp from 'assets/svg/stats-up.svg';

const servicesItems = [
    {
        icon: IconCode,
        title: 'Frontend kódování',
        description:
            'Převod designu do validních, responzivních a optimalizovaných HTML&nbsp;/&nbsp;CSS&nbsp;/&nbsp;JS šablon.',
    },
    {
        icon: IcoWebsite,
        title: 'Webové stránky',
        description:
            'Vytvoření webu od A-Z, umístění na server a veškeré nastavení. Následná údržba a&nbsp;vývoj.',
    },
    {
        icon: IcoStatsUp,
        title: 'Optimalizace',
        description:
            'Analýza, konzultace a následná optimalizace rychlosti načítání webových stránek.',
    },
];

class Services extends React.PureComponent {
    render() {
        const {sectionRef, onClick} = this.props;

        return (
            <section className="section" ref={sectionRef} id="services">
                <div className="wrapper">
                    <Heading
                        element="h2"
                        title="Moje služby"
                        modifiers="heading--center"
                    />
                    <div className="services">
                        {servicesItems.map((item, index) => (
                            <ServicesItem
                                icon={item.icon}
                                title={item.title}
                                description={item.description}
                                key={index}
                                onClick={e => onClick(item.title, e)}
                            />
                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

export default Services;
