import React from 'react';

class ServicesItem extends React.PureComponent {
	render() {

		const {icon, title, description, onClick} = this.props;
		const Icon = icon;

		return (
			<div className="services__item">
				<a href="#contact" className="services__item-inner" onClick={onClick}>
					<Icon className="services__item-icon" width="45" height="45"  />
					<p className="services__item-title">{title}</p>
					<p className="services__item-description" dangerouslySetInnerHTML={{__html:description}} />
				</a>
			</div>
		)
	}
}

export default ServicesItem